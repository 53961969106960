<script setup>

import {onMounted, ref} from "vue";
import Loader from "@/components/loader.vue";
import {useEnv} from "@/composables/useEnv";
import {useMoney} from "@/composables/useMoney";

const props = defineProps(['prod'])

const prod = ref(parseInt(props.prod))
const { base_url} = useEnv()
const url = base_url+'products/best_seller'

const data = ref({})
const loading = ref(true)


const money = useMoney();

const getImg = (url)=>{
  return url
}

onMounted(() => {
  fetch(url).then(response => response.json()).then(json=>{
    data.value = json.data
    loading.value = false
  })
})


</script>

<template>
  <section id="sellers">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-center">
          <h2 class="text-black">Más vendidos</h2>
          <div class="de-separator"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 pt-5 pb-5" v-if="loading">
          <loader/>
        </div>
      </div>
      <div class="seller">
        <div class="best-seller">
          <div class="best-seller-item" v-for="item in data" :key="i">
            <img :src="getImg(item.image_principal)" alt="img">
            <div class="best-p1-txt">
              <div class="name-of-p">
                <h2 class="mb0">{{ item.name }}</h2>
              </div>
              <p class="mb0 brand">{{ item.brand }}</p>
              {{ item.ranking }}
              <div class="rating">
                <i class="ri-star-fill" v-for="i in parseInt(item.ranking)"></i>
                <i class="ri-star-line" v-for="i in 5 - parseInt(item.ranking)"></i>
              </div>
              <div class="price">
                &dollar;{{ money.money(item.price) }}
              </div>
              <div class="buy-now">
                <button class="btn-main" @click="$router.push({name:'product', params:{product_id:item.id}})">Comprar Ahora</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

#sellers{
  background: white!important;
  padding: 45px 0 40px 0!important;
}

@media screen and (max-width: 768px) {
  .best-seller-item{
    width: 180px!important;
    padding: 0!important;
    img{
      width: 180px!important;
      height: 180px!important;
    }
    .buy-now{
      margin: 0!important;
    }
  }
}



.best-seller {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;


  &-item{
    margin-top: 25px;
    width: 250px;
    padding: 0 0 15px 0;
    box-shadow: -1px 5px 20px 0px rgba(0, 0, 0, 0.25), 2px 1px 8px rgba(0, 0, 0, 0.22);

    img{
      width: 250px;
      height: 250px;
    }

    .name-of-p{
      h2{
        color: #0d0c22;
        font-size: 16px;
        line-height: 2;
      }
    }
    .brand{
      font-size: 13px;
      line-height: 1;
    }
    .buy-now{
      margin-top: 5px;
    }
  }

}



</style>