<script setup>

</script>

<template>
  <section class="banner_uno">
    <div class="de-gradient-edge-top"></div>
    <div class="container relative z1000">
      <div class="row align-items-center">
        <div class="col-lg-6" >
          <img src="@/assets/images/bg_samuel.jpg" class="img-fluid " alt="">
        </div>
        <div class="col-lg-6">
          <h2 ><span class="id-color">Rockwell</span></h2>
          <p class="text-white">Tres generaciones de afiladores para herramientas de peluqueros y estilistas han pasado para que decidieramos crear una marca de tijeras compitiendo en el mercado.</p>
          <p class="text-white">Con más de 60 años de experiencia en afilado y conocimiento profundo de las calidades y evoluciones de las tijeras para corte de cabello, estamos capacitados para satisfacer la mayoría de las necesidades de cada cliente.</p>
          <p class="text-white">Nos enorgullece saber que actualmente somos una marca que ofrece altos estándares de calidad en nuestros productos y servicios, ubicándonos entre las mejores en el sector.</p>
          <a @click="$router.push({name:'about'})" class="btn-main  " >Sobre nosotros</a>
        </div>
      </div>
    </div>
    <div class="de-gradient-edge-bottom"></div>
  </section>
</template>

<style lang="scss" scoped>
.img-fluid{
  max-width: 400px;
  max-height: 400px;
}
.banner_uno{
  background-image: url("@/assets/images/bg_01.jpeg") ;
  background-size: cover;
  background-position-y: 50%;
  position: relative;
  padding: 50px 0;
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.75);
    left: 0;
    top: 0;
  }
}
</style>