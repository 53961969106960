<script setup>

import { ref, onMounted } from 'vue'
import Loader from "@/components/loader.vue";
import {useEnv} from "@/composables/useEnv";
const data = ref([])
const loading = ref(true)
const props = defineProps(['showBtn','numCat','showTitle'])

const showBtn = ref(props.showBtn??false)
const showTitle = ref(props.showTitle??false)
const numCat = ref(parseInt(props.numCat??4))
const { base_url} = useEnv()
const url = base_url+'categories/list'


const getImg = (url)=>{
  return url
}


onMounted(() => {
    fetch(url).then(response => response.json()).then(json=>{
      data.value = json.data
      loading.value = false
    })
})

</script>

<template>
  <section aria-label="section" class="categories_section" id="collection">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center" v-if="showTitle">
          <h2 class="text-black">Categorias</h2>
          <div class="de-separator"></div>
        </div>
        <div class="col-md-12 pt-5 pb-5" v-if="loading">
          <loader/>
        </div>
        <template v-else>
          <div class="col-6 col-md-3 text-center cursor-pointer category"
               @click="$router.push({name:'category', params:{category_code:item.code}})"
               v-for="item in data"
               :key="'cat'+item.id"
          >
            <div class="de-box-a">
              <div class="d-image">
                <img :src="getImg(item.image)" alt="">
              </div>
              <div class="d-deco-1"></div>
              <div class="d-deco-2"></div>
            </div>
            <div class="spacer-20"></div>
            <h4 class="text-black">{{ item.name }}</h4>
          </div>
        </template>
      </div>
      <div class="spacer-single"></div>
      <div class="text-center"  v-if="showBtn">
        <a @click="$router.push({name:'categories'})" class="btn-main cursor-pointer" >Ver todas las categorias</a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>



.category{
  &:hover{
    .d-deco-1,.d-deco-2{
      transition: all .1s;
      border-width: 3px;
    }
  }
}

.categories_section{
  background: white;
  padding: 45px 0 40px 0!important;
}


</style>