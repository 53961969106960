<script setup>

</script>

<template>

  <section id="subheader" class="jarallax">
    <img src="@/assets/images/bg_7.jpg" class="bg_image_title" alt="">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 text-center" style="z-index: 20">
          <h1>Sobre nosotros</h1>
          <div class="de-separator"></div>
        </div>
      </div>
    </div>
    <div class="de-gradient-edge-bottom"></div>
  </section>

  <section aria-label="section" class="no-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12" >
          <p class="lead big ">
            Nos enorgullece afirmar que somos una marca mexicana de alcance nacional e internacional. Nos destacamos por cumplir con los más rigurosos estándares de calidad en nuestras herramientas, empleando los prestigiosos aceros japoneses que dan vida a nuestras tijeras para corte de cabello.
          </p>
          <p class="lead big ">
            Hacemos de ROCKWELL nuestro estandarte de excelencia, servicio y compromiso con cada uno de nuestros clientes. Esta sólida asociación nos permite garantizar la calidad y la satisfacción en cada producto que ofrecemos.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class=" no-top">
    <div class="de-gradient-edge-top"></div>
    <img src="@/assets/images/bg_1.png" class="bg_image_title" alt="">
    <div class="container relative z1000">
      <div class="row gx-5">
        <div class="col-lg-6" >
          <div class="d-sch-table">
            <h2 class="text-center">Nuestra Misión</h2>
            <div class="de-separator"></div>
            <p class="text-white fz19">
              Nuestra misión es ser la inspiración detrás del arte en el cabello, proporcionando herramientas de calidad excepcional que potencien la creatividad y la destreza de peluqueros y estilistas. Nos comprometemos a ofrecer soluciones innovadoras y versátiles que cubran todas las necesidades del sector, respaldadas por un compromiso inquebrantable con la excelencia, la calidad y el servicio al cliente.
            </p>
            <div class="d-deco"></div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="d-sch-table">
            <h2 class=" text-center">Nuestra Visión</h2>
            <div class="de-separator"></div>
            <p class="text-white fz19">
              Nuestra visión es que cada vez que nuestros clientes escuchen 'Rockwell', asocien la marca con sinónimos de calidad indiscutible, garantía confiable y un servicio excepcional en todo momento. Queremos ser reconocidos como el estándar de excelencia y confianza en la industria, siendo la primera elección para aquellos que buscan lo mejor para su trabajo y sus clientes.
            </p>
            <div class="d-deco"></div>
          </div>
        </div>
      </div>
      <div class="row mt-5">


        <div class="col-lg-6 offset-lg-3">
          <div class="d-sch-table">
            <h2 class=" text-center">Nuestra Valores</h2>
            <div class="de-separator"></div>
            <ul class="valores">
              <li>
                <span>Compromiso</span> <br> Brindar apoyo y asistencia constante en cada momento.
              </li>
              <li>
                <span>Pasión</span> <br> Amar profundamente lo que representamos y lo que hacemos.
              </li>
              <li>
                <span>Lealtad</span> <br> Actuar con respeto, nobleza y honestidad en todas nuestras interacciones, manteniendo un compromiso inquebrantable con nuestros principios y con quienes confían en nosotros.
              </li>
            </ul>
            <div class="d-deco"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="de-gradient-edge-bottom"></div>
  </section>

  <section aria-label="section" v-if="false" class="no-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="">Nuestro Equipo</h2>
          <div class="de-separator"></div>
        </div>
        <div class="col-lg-3 offset-lg-3 text-center" >
          <div class="de-box-a">
            <div class="d-image">
              <img src="@/assets/images/users/samuel.jpeg" alt="">
            </div>
            <div class="d-deco-1"></div>
            <div class="d-deco-2"></div>
            <div class="d-social">
              <a href="#"><i class="fa fa-facebook"></i></a>
              <a href="#"><i class="fa fa-twitter"></i></a>
              <a href="#"><i class="fa fa-instagram"></i></a>
            </div>
          </div>
          <div class="spacer-20"></div>
          <h4>Samuel Olmedo</h4>
        </div>

        <div class="col-lg-3  text-center">
          <div class="de-box-a">
            <div class="d-image">
              <img src="@/assets/images/users/brayan.jpg" alt="">
            </div>
            <div class="d-deco-1"></div>
            <div class="d-deco-2"></div>
            <div class="d-social">
              <a href="#"><i class="fa fa-facebook"></i></a>
              <a href="#"><i class="fa fa-twitter"></i></a>
              <a href="#"><i class="fa fa-instagram"></i></a>
            </div>
          </div>
          <div class="spacer-20"></div>
          <h4>Brayan Olmedo</h4>
        </div>
      </div>
    </div>
  </section>





</template>

<style scoped lang="scss">


.fz19{
  font-size: 19px;
}

.bg_image_title{
  position: absolute;
  left: 0;
  z-index: 0;
  width: 100vw;
  top: 0;
}

.valores{
  list-style: none;
  li{
    color: white;
    font-size: 19px;
    margin-top: 15px;
    span{
      font-size: 25px;
      font-weight: bold;
    }
  }
}

</style>