import './assets/scss/app.scss'
import './assets/css/bootstrap.min.css'


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import { Plugin } from 'vue-responsive-video-background-player'
import VueNavigationBar from 'vue-navigation-bar';
import 'vue-navigation-bar/dist/vue-navigation-bar.css';



let app = createApp(App)
app.use(store)
app.use(router)
app.use(Plugin)
app.component('vue-navigation-bar', VueNavigationBar)


app.config.globalProperties.URL_BASE = 'http://localhost/api/'




app.mount('#app')
