<script >
import { mapState } from 'vuex'
import VueLetterAvatar from "@/components/VueLetterAvatar.vue";
export default {
  components: {VueLetterAvatar},
  data () {
    return {
      showNavbar: true,
      openMenu: false,
      lastScrollPosition: 0,
      scrollValue: 0
    }
  },
  computed:{
    ...mapState('user',['user']),
    isLogin(){
      return this.$store.state.isLogin
    }
  },
  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    openRoute(route){
      this.$router.push({name: route});
      this.openMenu = false
    },
    singOut(route){

      localStorage.removeItem('login')
      localStorage.removeItem('at')
      localStorage.removeItem('rt')
      window.location.href = '/'
    },
    onScroll () {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < 60) {
        return
      }
      this.showNavbar = window.pageYOffset < 60
      this.lastScrollPosition = window.pageYOffset
    }
  }
}
</script>

<template>
  <header class=""  :class="{'transparent': showNavbar,'open': openMenu}">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="de-flex sm-pt10">
            <div class="de-flex-col">
              <div class="de-flex-col">
                <!-- logo begin -->
                <div id="logo">
                  <div @click="openRoute('home')" class="cursor-pointer">
                    <img class="logo-main" src="/images/logo_white.svg" alt="" >
                    <img class="logo-mobile" src="/images/logo_white.svg" alt="" >
                  </div>
                </div>
                <!-- logo close -->
              </div>
            </div>
            <div class="de-flex-col header-col-mid">
              <ul id="mainmenu">
                <li class="hidden_menu">
                  <a class="menu-item cursor-pointer"  v-if="isLogin"  @click="openRoute('dashboard')" >Perfil</a>
                </li>
                <li>
                  <a class="menu-item cursor-pointer" @click="openRoute('home')" >Inicio</a>
                </li>
                <li>
                  <a class="menu-item cursor-pointer" @click="openRoute('categories')" >Categorias</a>
                </li>
                <li>
                  <a class="menu-item cursor-pointer" @click="openRoute('catalog')" >Catalogo</a>
                </li>
                <li>
                  <a class="menu-item cursor-pointer" @click="openRoute('about')"  >Sobre Nosotros</a>
                </li>
                <li><a class="menu-item cursor-pointer" @click="openRoute('cart')" v-if="isLogin" >Carrito</a></li>
                <li class="hidden_menu"><a class="menu-item cursor-pointer" v-if="isLogin" @click="singOut" >Cerrar sesion</a></li>
              </ul>
            </div>
            <div class="de-flex-col">
              <div class="menu_side_area" v-if="!isLogin">
                <a @click="openRoute('sing_in')"  class="btn-main cursor-pointer">Ingresar</a>
                <span id="menu-btn" @click="openMenu=!openMenu"></span>
              </div>
              <div class="" v-else>
                <div class="menu_profile">
                    <div class="menu_profile_button cursor-pointer" @click="openMenu=!openMenu">
                      <vue-letter-avatar name="Juan Carlos" :size='35' rounded="50%" />
                    </div>
                    <div class="menu_profile_menu show_menu" v-if="openMenu">
                      <div class="menu_profile_menu_list">
                        <div class="menu_profile_menu_list_item"  @click="openRoute('dashboard')" >Perfil</div>
                        <div class="menu_profile_menu_list_item" @click="singOut" >Cerrar sesión</div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.hidden_menu{
  display: none!important;
}
.show_menu{
  display: block!important;
}

.menu_profile{
  position: relative;
  &_button{
    border: 1px solid white;
    border-radius: 50%;
  }
  &_menu{
    position: absolute;
    width: 150px;
    background: white;
    box-shadow: -1px 5px 20px 0px rgba(0, 0, 0, 0.25), 2px 1px 8px rgba(0, 0, 0, 0.22);
    bottom: -80px;
    right: -50px;
    padding: 5px;
    &_list{
      &_item{
        padding: 2px;
        line-height: 1.5;
        &:hover{
          background: var(--primary-color);
          cursor: pointer;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hidden_menu{
    display: block!important;
  }
  .show_menu{
    display: none!important;
  }
  header{
    height: 70px;
    top: 0;
    left: 0;
    position: fixed;
    overflow: hidden;

    &.open{
      height: 100vh;
      background: black!important;
      #menu-btn{
        &:before{
          content: "\f00d";
        }
      }
    }
    &.transparent{
      background: transparent;
    }
  }
}

</style>